<template>
	<v-container
		id="LevelDetail"
		fluid
		tag="section">
		<v-row dense>
			<v-col cols="12">
				<KeyValueCard2
					color="secondary"
					:loading="getJokerBonusDetail.isLoading"
					:cols="bonusStateCols"
					:item="jackpotDetail"
					:header="dataHeaders">
					<template v-slot:heading>
						<CardHeading title="Bonus State">
							<div class="d-flex">
								<ConfirmDialog2
									persistent
									:confirmBtnText="'Delete'"
									:cancelBtnText="'Back'"
									:confirmBtnColor="'error'"
									:titleClass="'error'"
									:cancelBtnColor="'kajot-text'"
									:shouldShow="true"
									v-if="
										_usrFlagsSome({
											key: 'bonus.groups.detail',
											val: permissions.DELETE,
										})
									"
									@confirm="handleDeactivateButton">
									<template v-slot:body>
										This will deactivate this Level.
										<br />
										<b>Do you want to proceed?</b>
									</template>
									<template v-slot:default="{ showConfirm }">
										<v-btn
											class="mr-3 ml-5"
											elevation="1"
											fab
											small
											color="error"
											link
											@click="showConfirm">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</template>
								</ConfirmDialog2>

								<edit-joker-bonus
									color="primary"
									v-if="
										_usrFlagsSome({
											key: 'bonus.groups.detail',
											val: permissions.EDIT,
										})
									"
									:editJokerBonusData="jokerBonusDataToEdit"
									v-on:newJokerBonusData="sendPatchedJokerBonus">
									<template v-slot:button="{ on, attrs }">
										<v-btn
											color="primary"
											elevation="1"
											fab
											small
											v-bind="attrs"
											v-on="on"
											@click="openJBState">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
								</edit-joker-bonus>
							</div>
						</CardHeading>
					</template>
					<template v-slot:[`item.z.value`]="{ item }">
						{{ item.z | Placeholder }}
					</template>
					<template v-slot:[`item.next_z.value`]="{ item }">
						{{ item.next_z | Placeholder }}
					</template>
					<template v-slot:[`item.created.value`]="{ item }">
						{{ item.created | Date | Placeholder }}
					</template>
					<template v-slot:[`item.win_stamp.value`]="{ item }">
						{{ item.win_stamp | Date }}
					</template>
				</KeyValueCard2>
			</v-col>

			<v-col
				cols="12"
				lg="9"
				md="8">
				<KeyValueCard2
					color="secondary"
					:loading="getJokerBonusDetail.isLoading"
					:cols="filteredjokerBonusConfigRows"
					:item="jackpotDetail"
					:header="dataHeaders">
					<template v-slot:heading>
						<CardHeading title="Joker Bonus Configuration">
							<EditJokerBonusConfig
								:editJokerBonusConfigData="jokerBonusConfigCopy"
								:jokerBonusContribType="contribType"
								color="primary"
								:jokerBonusConfigList="jackpotConfigList"
								@submit="onJBConfigEditSubmit"
								v-if="
									_usrFlagsSome({
										key: 'bonus.bonus_config.detail',
										val: permissions.EDIT,
									})
								">
								<template v-slot:button="{ on, attrs }">
									<v-btn
										color="primary"
										elevation="1"
										fab
										small
										v-bind="attrs"
										v-on="on"
										@click="openJBEdit">
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
							</EditJokerBonusConfig>
						</CardHeading>
					</template>
					<template v-slot:[`item.contrib_type.value`]="{ item }">
						{{ item.contrib_type | Capitalize }}
					</template>
					<template v-slot:[`item.contrib_ratio.value`]="{ item }">
						{{ item.contrib_ratio | Placeholder(0) | DecimalPoints }}%
					</template>
					<template v-slot:[`item.contrib_bet_range.value`]="{ item }">
						{{
							item.contrib_bet_range ? item.contrib_bet_range.join(" - ") : ""
						}}
					</template>
					<template v-slot:[`item.jackpot_type.value`]="{ item }">
						{{ item.jackpot_type | Capitalize | Placeholder }}
					</template>
					<template v-slot:[`item.distribution.value`]="{ item }">
						{{ item.distribution ? item.distribution.toUpperCase() : "" }}
					</template>
					<template v-slot:[`item.selection.value`]="{ item }">
						{{ item.selection | Capitalize }}
					</template>
					<template v-slot:[`item.candidates.value`]="{ item }">
						{{ item.candidates | Capitalize }}
					</template>
					<template v-slot:[`item.probability.value`]="{ item }">
						{{ item.probability | Capitalize }}
					</template>
					<template v-slot:[`item.trim.value`]="{ item }">
						{{ item.trim + "%" }}
					</template>
					<template v-slot:[`item.active_time.value`]="{ item }">
						{{ item.active_time + "s" }}
					</template>
				</KeyValueCard2>
			</v-col>

			<v-col
				cols="12"
				lg="3"
				md="4">
				<v-card class="pa-3 px-5 py-3">
					<card-heading
						color="secondary"
						title="Level Preview">
						<template v-slot:default></template>
					</card-heading>

					<v-card-text>
						<LevelPreview
							previewWidth="100vw"
							previewHeight="7vh"
							previewBorderWidth="3px"
							:valueText="(jackpotDetail.main_value / 100).toFixed(2)"
							:valueCurrency="jackpotDetail.currency"
							:iconUrl="theme && theme.iconUrl"
							:iconTint="theme && theme.iconTint"
							:backgroundColors="theme && theme.backgroundColors"
							:backgroundColorStops="theme && theme.backgroundColorStops"
							:textColors="theme && theme.textColors"
							:textColorStops="theme && theme.textColorStops"
							:borderColors="theme && theme.borderColors"
							:borderColorStops="theme && theme.borderColorStops"
							:bonusLevelIconUrl="theme && theme.bonusLevelIconUrl"
							:visible_name="theme && theme.visible_name"
							:image_name="this.jackpotDetail.level_name" />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import KeyValueCard2 from "../../components/shared/keyValueCard2.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import LevelPreview from "../../components/shared/LevelPreview.vue";
import EditJokerBonus from "../../components/jackpots/jackpot/EditJokerBonus.vue";
import EditJokerBonusConfig from "../../components/jackpots/jackpot/EditJokerBonusConfig.vue";
import { DISTRIBUTION_TYPES } from "../../constants/distributions";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import permissions from "../../mixins/permissions";

export default {
	mixins: [permissions],
	components: {
		CardHeading,
		LevelPreview,
		EditJokerBonus,
		EditJokerBonusConfig,
		KeyValueCard2,
		ConfirmDialog2,
	},
	data() {
		return {
			jokerBonusConfigCopy: {},
			jokerBonusDataToEdit: undefined,
			jokerBonusConfig: undefined,
			jokerBonusConfigToEdit: undefined,
			validationConfig: undefined,
			jackpotWinnerCriteria: undefined,
			jokerBonusListFilteredByConfId: [],
			dataHeaders: [
				{
					sortable: false,
					text: "KEY",
					value: "key",
				},
				{
					sortable: false,
					text: "VALUE",
					value: "value",
				},
			],
			cleanUp: false,
		};
	},

	computed: {
		...mapGetters("jackpot2", {
			jackpotDetail: "detail",
		}),
		...mapGetters("jackpotConfig2", {
			jackpotConfigDetail: "detail",
			jackpotConfigList: "list",
			jackpotConfigListPagination: "pagination",
		}),
		...mapGetters("jackpotGroup2", {
			jackpotGroupDetail: "detail",
		}),
		...mapGetters("jackpotTheme2", {
			jackpotThemeDetail: "detail",
			levelColorScheme: "levelScheme",
		}),

		groupCurrency() {
			return this.jackpotGroupDetail?.currency ?? "";
		},
		contribType() {
			return this.jackpotGroupDetail?.contrib_type;
		},
		theme() {
			if (this.jackpotDetail?.level_name !== undefined)
				return this.levelColorScheme(this.jackpotDetail?.level_name);

			return {
				backgroundColors: null,
				backgroundColorStops: null,
				borderColors: null,
				borderColorStops: null,
				bonusLevelIconUrl: null,
				visible_name: null,
			};
		},
		...mapGetters([
			"getJokerBonusDetail",
			"getSpecificTheme",
			"getDefaultScheme",
			"getFilteredJBConfigsList",
			"getLevelDetail",
			"getUpdatedJokerBonus",
			"getJBConfigDetail",
			"getUpdatedJBConfigDetail",
			"getCreatedJBConfig",
			"filteredJokerBonusList",
			"getGroupsGroupConfiguration",
		]),
		filteredjokerBonusConfigRows() {
			return this.jokerBonusConfigRows
				.filter((el) => {
					const res = !el.hide?.();
					return res;
				})
				.map((el) => (Array.isArray(el) ? el : el.items));
		},
		jokerBonusConfigRows() {
			return [
				{
					items: [
						{ label: "Contrib Type", key: "contrib_type" },
						{ label: "Contrib Bet Range", key: "contrib_bet_range" },
					],
					hide: () => this.jackpotDetail.contrib_type !== "bet",
				},
				{
					items: [
						{ label: "Contrib Type", key: "contrib_type" },
						{ label: "Contrib Ratio", key: "contrib_ratio" },
					],
					hide: () => this.jackpotDetail.contrib_type !== "percentage",
				},
				[
					{ label: "Min Value", key: "min_value" },
					{ label: "Max Value", key: "max_value" },
				],
				[
					{ label: "Bonus Type", key: "jackpot_type" },
					{ label: "Min Bonus", key: "min_jack" },
				],
				[{ label: "Distribution", key: "distribution" }],
				{
					items: [{ label: "K", key: "k" }],
					hide: () =>
						this.jackpotDetail.distribution_id !== DISTRIBUTION_TYPES.K_UNIFORM,
				},
				{
					items: [
						{ label: "Mean", key: "mean" },
						{ label: "Variance", key: "variance" },
					],
					hide: () =>
						this.jackpotDetail.distribution_id !== DISTRIBUTION_TYPES.BETA,
				},
				[
					{ label: "Count", key: "count" },
					{ label: "Shares", key: "shares" },
				],
				[
					{ label: "Selection", key: "selection" },
					{ label: "Candidates", key: "candidates" },
				],
				[
					{ label: "Probability", key: "probability" },
					{ label: "Trim", key: "trim" },
				],
				[
					{ label: "Min Contribution", key: "min_contribution" },
					{ label: "Active Time", key: "active_time" },
				],
			];
		},
		bonusStateCols() {
			return [
				{ label: "Main Value", key: "main_value" },
				{ label: "Side Value", key: "side_value" },
				{ label: "Z", key: "z" },
				{ label: "Next Z", key: "next_z" },
				{ label: "Created", key: "created" },
				{ label: "Win Stamp", key: "win_stamp" },
			];
		},
	},

	methods: {
		...mapActions("jackpot2", {
			loadJackpotDetail: "loadDetail",
			updateJackpot: "update",
		}),
		...mapActions("jackpotConfig2", {
			loadJackpotConfigDetail: "loadDetail",
			loadJackpotConfigList: "loadList",
		}),
		...mapMutations("jackpotConfig2", {
			setJackpotConfigListPagination: "pagination",
		}),
		...mapActions("jackpotGroup2", {
			loadGroupDetail: "loadDetail",
		}),
		...mapActions("jackpotTheme2", {
			loadThemeDetail: "loadDetail",
		}),
		...mapActions([
			"loadJokerBonusDetail",
			"deactivateJokerBonus",
			"setBreadCrumbs",
			"loadSpecificTheme",
			"loadJackpotGroupDetail",
			"loadFilteredJBConfigs",
			"loadLevelDetail",
			"updateJokerBonus",
			"loadJBConfigDetail",
			"addJBConfig",
			"updateJBConfig",
			"loadFilteredJokerBonusList",
			"loadGroupsGroupConfiguration",
		]),

		handleBackButton() {
			this.$router.push({
				name: "jackpot-group-detail",
				params: { jackpot_group_id: this.jackpotDetail.group_id },
			});
		},

		handleDeactivateButton() {
			this.deactivateJokerBonus(this.jackpotDetail.id);
			this.handleBackButton();
		},

		formatBetRange(arr) {
			if (arr) {
				return arr.join(" - ");
			} else {
				return undefined;
			}
		},

		formatShares(arr) {
			if (arr) {
				return arr.map((el) => el + "%").join(" - ");
			} else {
				return undefined;
			}
		},

		async openJBEdit() {
			this.setJackpotConfigListPagination({
				...this.jackpotConfigListPagination,
				per_page: 150,
			});
			await this.loadJackpotConfigList();
			this.jokerBonusConfigCopy = { ...this.jackpotConfigDetail };
		},
		async onJBConfigEditSubmit(e) {
			if (
				e.id == null ||
				e.id == undefined ||
				e.id == this.jackpotDetail.jackpot_config_id
			)
				return;
			const data = {
				// level_id: this.jackpotDetail.level_id, NO IDEA WHY
				jackpot_config_id: e.id,
			};
			// payload.jackpot_config_id = e.id;
			await this.updateJackpot({
				id: this.jackpotDetail.id,
				data,
			});
			await this.reload();
		},
		openJBState() {
			this.jokerBonusDataToEdit = { ...this.jackpotDetail };
		},
		async sendPatchedJokerBonus({ main_value, side_value, z, next_z }) {
			const data = {
				main_value,
				side_value,
				z,
				next_z,
			};

			await this.updateJackpot({
				id: this.jackpotDetail.id,
				data,
			});
			await this.reload();
		},

		async reload() {
			await this.loadJackpotDetail(this.$route.params.jackpot_id);
			this.setBreadCrumbs({
				pageName: this.jackpotDetail.group_name,
				pageId: this.jackpotDetail.group_id,
				subPageName: this.jackpotDetail.level_name,
			});
			await Promise.all([
				this.loadJackpotConfigDetail(this.jackpotDetail.jackpot_config_id),
				this.loadGroupDetail(this.jackpotDetail.group_id),
			]);
			await this.loadThemeDetail(this.jackpotGroupDetail.theme_name);
		},
	},

	async created() {
		await this.reload();
	},
};
</script>
