<template>
	<div class="text-center">
		<v-dialog
			v-model="jokerBonusEditDialog"
			width="300px"
			class="ml-auto mr-auto">
			<template v-slot:activator="{ on, attrs }">
				<slot
					name="button"
					:on="on"
					:attrs="attrs"></slot>
			</template>

			<v-card>
				<v-card-title
					v-if="Object.keys(editJokerBonusData).length === 0"
					:class="'headline ' + color">
					Add Joker Bonus
				</v-card-title>
				<v-card-title
					v-else
					:class="'headline ' + color">
					Edit Joker Bonus
				</v-card-title>
				<v-card-text
					class="px-10 py-4"
					v-if="Object.keys(editJokerBonusData).length > 0">
					<joker-bonus-form
						:value="editJokerBonusData"
						@input="JBUpdate"></joker-bonus-form>
				</v-card-text>
				<v-card-text
					v-else
					class="text-center subtitle-1">
					There is no data to display, create Joker Bonus first, this must be an
					error
				</v-card-text>

				<v-divider />

				<v-card-actions col="12">
					<v-btn
						text
						@click="jokerBonusEditDialog = false">
						Cancel
					</v-btn>
					<v-spacer />
					<v-btn
						:disabled="disableSubmit"
						color="primary"
						text
						@click="submit">
						Submit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import JokerBonusForm from "./JokerBonusForm.vue";

export default {
	components: {
		JokerBonusForm,
	},

	props: {
		editJokerBonusData: {
			type: Object,
			default: function () {
				return {};
			},
		},
		color: {
			type: String,
			default: "primary",
		},
	},

	data() {
		return {
			toSubmit: false,
			disableSubmit: true,

			jokerBonusEditDialog: false,
			formVal: {},
		};
	},

	methods: {
		JBUpdate([val, valid]) {
			this.formVal = val;
			this.disableSubmit = !valid;
		},

		async submit() {
			this.$emit("newJokerBonusData", this.formVal);
			this.jokerBonusEditDialog = false;
		},
	},
};
</script>
