var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "ml-auto mr-auto",
          attrs: { width: "300px" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [_vm._t("button", null, { on: on, attrs: attrs })]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.jokerBonusEditDialog,
            callback: function ($$v) {
              _vm.jokerBonusEditDialog = $$v
            },
            expression: "jokerBonusEditDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              Object.keys(_vm.editJokerBonusData).length === 0
                ? _c("v-card-title", { class: "headline " + _vm.color }, [
                    _vm._v(" Add Joker Bonus "),
                  ])
                : _c("v-card-title", { class: "headline " + _vm.color }, [
                    _vm._v(" Edit Joker Bonus "),
                  ]),
              Object.keys(_vm.editJokerBonusData).length > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "px-10 py-4" },
                    [
                      _c("joker-bonus-form", {
                        attrs: { value: _vm.editJokerBonusData },
                        on: { input: _vm.JBUpdate },
                      }),
                    ],
                    1
                  )
                : _c("v-card-text", { staticClass: "text-center subtitle-1" }, [
                    _vm._v(
                      " There is no data to display, create Joker Bonus first, this must be an error "
                    ),
                  ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { attrs: { col: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.jokerBonusEditDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.disableSubmit,
                        color: "primary",
                        text: "",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }