var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "LevelDetail", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  color: "secondary",
                  loading: _vm.getJokerBonusDetail.isLoading,
                  cols: _vm.bonusStateCols,
                  item: _vm.jackpotDetail,
                  header: _vm.dataHeaders,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "heading",
                      fn: function () {
                        return [
                          _c(
                            "CardHeading",
                            { attrs: { title: "Bonus State" } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _vm._usrFlagsSome({
                                    key: "bonus.groups.detail",
                                    val: _vm.permissions.DELETE,
                                  })
                                    ? _c("ConfirmDialog2", {
                                        attrs: {
                                          persistent: "",
                                          confirmBtnText: "Delete",
                                          cancelBtnText: "Back",
                                          confirmBtnColor: "error",
                                          titleClass: "error",
                                          cancelBtnColor: "kajot-text",
                                          shouldShow: true,
                                        },
                                        on: {
                                          confirm: _vm.handleDeactivateButton,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " This will deactivate this Level. "
                                                  ),
                                                  _c("br"),
                                                  _c("b", [
                                                    _vm._v(
                                                      "Do you want to proceed?"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "default",
                                              fn: function ({ showConfirm }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-3 ml-5",
                                                      attrs: {
                                                        elevation: "1",
                                                        fab: "",
                                                        small: "",
                                                        color: "error",
                                                        link: "",
                                                      },
                                                      on: {
                                                        click: showConfirm,
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-delete"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3453383262
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._usrFlagsSome({
                                    key: "bonus.groups.detail",
                                    val: _vm.permissions.EDIT,
                                  })
                                    ? _c("edit-joker-bonus", {
                                        attrs: {
                                          color: "primary",
                                          editJokerBonusData:
                                            _vm.jokerBonusDataToEdit,
                                        },
                                        on: {
                                          newJokerBonusData:
                                            _vm.sendPatchedJokerBonus,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            elevation: "1",
                                                            fab: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openJBState,
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-pencil"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          44499521
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.z.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Placeholder")(item.z)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.next_z.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("Placeholder")(item.next_z)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.created.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Placeholder")(
                                  _vm._f("Date")(item.created)
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.win_stamp.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Date")(item.win_stamp)) + " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "9", md: "8" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  color: "secondary",
                  loading: _vm.getJokerBonusDetail.isLoading,
                  cols: _vm.filteredjokerBonusConfigRows,
                  item: _vm.jackpotDetail,
                  header: _vm.dataHeaders,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "heading",
                      fn: function () {
                        return [
                          _c(
                            "CardHeading",
                            { attrs: { title: "Joker Bonus Configuration" } },
                            [
                              _vm._usrFlagsSome({
                                key: "bonus.bonus_config.detail",
                                val: _vm.permissions.EDIT,
                              })
                                ? _c("EditJokerBonusConfig", {
                                    attrs: {
                                      editJokerBonusConfigData:
                                        _vm.jokerBonusConfigCopy,
                                      jokerBonusContribType: _vm.contribType,
                                      color: "primary",
                                      jokerBonusConfigList:
                                        _vm.jackpotConfigList,
                                    },
                                    on: { submit: _vm.onJBConfigEditSubmit },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        elevation: "1",
                                                        fab: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: _vm.openJBEdit,
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-pencil"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2912317002
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.contrib_type.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("Capitalize")(item.contrib_type)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.contrib_ratio.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("DecimalPoints")(
                                  _vm._f("Placeholder")(item.contrib_ratio, 0)
                                )
                              ) +
                              "% "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.contrib_bet_range.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.contrib_bet_range
                                  ? item.contrib_bet_range.join(" - ")
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.jackpot_type.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("Placeholder")(
                                  _vm._f("Capitalize")(item.jackpot_type)
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.distribution.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.distribution
                                  ? item.distribution.toUpperCase()
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.selection.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("Capitalize")(item.selection)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.candidates.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("Capitalize")(item.candidates)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.probability.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("Capitalize")(item.probability)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.trim.value`,
                      fn: function ({ item }) {
                        return [_vm._v(" " + _vm._s(item.trim + "%") + " ")]
                      },
                    },
                    {
                      key: `item.active_time.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(" " + _vm._s(item.active_time + "s") + " "),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "3", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-3 px-5 py-3" },
                [
                  _c("card-heading", {
                    attrs: { color: "secondary", title: "Level Preview" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return undefined
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "v-card-text",
                    [
                      _c("LevelPreview", {
                        attrs: {
                          previewWidth: "100vw",
                          previewHeight: "7vh",
                          previewBorderWidth: "3px",
                          valueText: (
                            _vm.jackpotDetail.main_value / 100
                          ).toFixed(2),
                          valueCurrency: _vm.jackpotDetail.currency,
                          iconUrl: _vm.theme && _vm.theme.iconUrl,
                          iconTint: _vm.theme && _vm.theme.iconTint,
                          backgroundColors:
                            _vm.theme && _vm.theme.backgroundColors,
                          backgroundColorStops:
                            _vm.theme && _vm.theme.backgroundColorStops,
                          textColors: _vm.theme && _vm.theme.textColors,
                          textColorStops: _vm.theme && _vm.theme.textColorStops,
                          borderColors: _vm.theme && _vm.theme.borderColors,
                          borderColorStops:
                            _vm.theme && _vm.theme.borderColorStops,
                          bonusLevelIconUrl:
                            _vm.theme && _vm.theme.bonusLevelIconUrl,
                          visible_name: _vm.theme && _vm.theme.visible_name,
                          image_name: this.jackpotDetail.level_name,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }